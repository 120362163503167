import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useStateStore } from "../../store";
import "./EmailBlock.css";
import PeopleBlock from "./PeopleBlock";
import { mixpanel } from "../../index";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import EmailComposer from "./EmailComposer.js";

function EmailBlock({ setSendEmail }) {
  const textareaRef = useRef(null);
  const history = useHistory();
  const resumeData = useRef({});
  const setShowEmailSentSuccess = useStateStore(
    (state) => state.setShowEmailSentSuccess
  );
  const emailAutomationReferral = useStateStore(
    (state) => state.emailAutomationReferral
  );
  const setEmailList = useStateStore((state) => state.setEmailList);
  const emailList = useStateStore((state) => state.emailList);
  const [regenerateLoading, setRegenerateLoading] = useState(false);
  const [activeEmailLoading, setActiveEmailLoading] = useState(false);
  const [resumefetchingStatus, setResumeFetchingStatus] = useState(false);
  const setReferralDataOtherDetails = useStateStore(
    (state) => state.setReferralDataOtherDetails
  );
  const setStepNoReferralFeedback = useStateStore(
    (state) => state.setStepNoReferralFeedback
  );
  const [emailNotFound, setEmailNotFound] = useState(false);
  const [sendEmailButtonLoadingStatus, setSendEmailButtonLoadingStatus] =
    useState(false);
  const userData = useStateStore((state) => state.userData);
  const [selectedResumeId, setSelectedResumeId] = useState("");
  const [showImcompleteDataError, setShowIncompleteDataError] = useState(false);
  const setReferralResumeData = useStateStore(
    (state) => state.setReferralResumeData
  );
  const setShowMainReferralModal = useStateStore(
    (state) => state.setShowMainReferralModal
  );
  const referralResumeData = useStateStore((state) => state.referralResumeData);
  const setReferralInitData = useStateStore(
    (state) => state.setReferralInitData
  );
  const referralInitData = useStateStore((state) => state.referralInitData);
  const [referralTitle, setReferralTitle] = useState("");
  const employeeSelected = useStateStore((state) => state.employeeSelected);
  const mainCompanySet = useStateStore((state) => state.mainCompanySet);
  const initialString  = useStateStore(state=>state.initialString)
  const setInitialString = useStateStore(state=>state.setInitialString)
  const setEmailDataString = useStateStore((state) => state.setEmailDataString);
  const [text, setText] = useState("");
  const [emailTitle, setEmailTitle] = useState("");
  const emailData = useStateStore((state) => state.emailData);
  const setEmailData = useStateStore((state) => state.setEmailData);
  const [showLoading, setShowLoading] = useState(false);
  const saveButtonClickAndSuccess = useStateStore(
    (state) => state.saveButtonClickAndSuccess
  );
  const setSaveButtonClickAndSuccess = useStateStore(
    (state) => state.setSaveButtonClickAndSuccess
  );
  const token = useStateStore((state) => state.token);
  const [nowShowMainSendEmailButton, setNowShowMainSendEmailButton] =
    useState(false);
  const showEmailSettings = useStateStore((state) => state.showEmailSettings);
  const setShowEmailSettings = useStateStore(
    (state) => state.setShowEmailSettings
  );
  const setEditEmailSettings = useStateStore(
    (state) => state.setEditEmailSettings
  );
  const editEmailSettings = useStateStore((state) => state.editEmailSettings);

  const getEmail = async () => {
    if (employeeSelected.linkedinUrl in emailList) {
      setEmailData({
        email: emailList[employeeSelected.linkedinUrl],
      });
      return {
        email: emailList[employeeSelected.linkedinUrl],
      };
    } else {
      const response = await axios.post(
        `https://backend.lazyapply.com/referral/getEmailMain`,
        {
          employee: employeeSelected,
          // employeeId: employeeSelected.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("getEmailData", response.data.emailData);
      setEmailData(response.data.emailData);
      setEmailList({
        linkedin: employeeSelected.linkedinUrl,
        email: response.data.emailData.email,
      });
      return response.data.emailData;
    }
  };

  const sendEmail = async () => {
    if (emailData?.email && emailData.email != "") {
      setSendEmailButtonLoadingStatus(true);
      const sendResponse = await axios.post(
        "https://backend.lazyapply.com/referral/sendEmail",
        {
          employee: employeeSelected,
          referraltitle: referralInitData?.referral?.referralTitle,
          companyDetails: mainCompanySet,
          resumeId: referralInitData?.referral?.resumeId,
          v2Id: userData?.planDetails?.v2Id,
          // resumeJson:
          //   referralResumeData.resumes[referralInitData?.referral?.resumeId],
          emailData: emailData,
          emailDescription: initialString,
          emailTitle: emailTitle,
          userData: userData,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("response send email", sendResponse);
      setSendEmailButtonLoadingStatus(false);
      setTimeout(() => {
        setSendEmail(false);
        if (!emailAutomationReferral) {
          setShowMainReferralModal(false);
        }
        setShowEmailSentSuccess(true);
      }, 500);
    } else {
      // email not found
    }
  };

  const setProfileSelectedDuringAutomation = useStateStore(
    (state) => state.setProfileSelectedDuringAutomation
  );
  const activeAiEmails = async () => {
    let referralTitleMain = referralInitData.referral.referralTitle;
    console.log("maintitle", selectedResumeId, referralTitleMain);
    if (referralTitle != "") {
      referralTitleMain = referralTitle;
    }
    setActiveEmailLoading(true);
    setReferralDataOtherDetails({
      referralTitle: referralTitleMain,
      selectedResumeId: selectedResumeId,
    });
    await axios.post(
      `https://backend.lazyapply.com/referral/updateReferralData`,
      {
        emailTemplateOption: emailTemplateOption,
        customTemplateId: customTemplateId,
        referralTitle: referralTitleMain,
        v2Id: userData?.planDetails?.v2Id,
        resumeId: selectedResumeId,
        permissionGranted: 1,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setSaveButtonClickAndSuccess(true);
    setEditEmailSettings(false);
    setShowEmailSettings(false);
    const referralData = referralInitData?.referral;
    setReferralInitData({
      ...referralInitData,
      referral: {
        ...referralData,
        emailTemplateOption: emailTemplateOption,
        customTemplateId: customTemplateId,
        resumeId: selectedResumeId,
        referralTitle: referralTitleMain,
      },
    });
    if (emailAutomationReferral) {
      setShowIncompleteDataError(false);
      setActiveEmailLoading(false);
      setProfileSelectedDuringAutomation(true);
    } else {
      setShowIncompleteDataError(false);
      setActiveEmailLoading(false);
    }
  };

  const writeEmail = async (rtitle = "", rid = "", email_data = {}) => {
    console.log("WRITE EMAIL", email_data);
    if (
      Object.keys(email_data).length > 0 &&
      email_data?.email &&
      email_data.email != ""
    ) {
      setText("");
      setInitialString("");
      setEmailDataString("");
      let title = referralInitData?.referral?.referralTitle || "";
      let id = referralInitData?.referral?.resumeId || "";
      if (rtitle != "") title = rtitle;
      if (rid != "") id = rid;
      console.log("email", emailData);
      setEmailNotFound(false);
      setShowLoading(true);
      const response = await axios.post(
        `https://backend.lazyapply.com/referral/writeEmail`,
        {
          employee: employeeSelected,
          referraltitle: title,
          companyDetails: mainCompanySet,
          // resumeJson: referralResumeData.resumes[id].resume,
          resumeId: id,
          v2Id: userData?.planDetails?.v2Id,
          referralInitData: useStateStore.getState().referralInitData,
          customTemplates: customTemplates,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("getEmailData", response.data);
      setShowLoading(false);
      setEmailTitle(`Referral For ${title} Position`);
      if (response.data.parsed) {
        setInitialString(response.data.content);
        setEmailDataString(response.data.content);
      } else {
        console.log("some error ocurred");
      }
    } else {
      setEmailNotFound(true);
    }
  };

  const fetchUserResumeData = async () => {
    setResumeFetchingStatus(true);
    const v2Id = userData.planDetails.v2Id;
    axios
      .get(
        `https://backend.lazyapply.com/v2/resumesV3/checkResumesValidation/${v2Id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setResumeFetchingStatus(false);
        setReferralResumeData(response.data);
      })
      .catch((err) => {
        setResumeFetchingStatus(false);
        setReferralResumeData([]);
        console.log("some error occured");
      });
  };

  const checkDisabled = () => {
    if (
      (emailAutomationReferral &&
        selectedResumeId != "" &&
        (emailTemplateOption == 2 ||
          (emailTemplateOption == 1 &&
            customTemplates.length > 0 &&
            customTemplateId != ""))) ||
      (!emailAutomationReferral &&
        referralTitle != "" &&
        selectedResumeId != "" &&
        (emailTemplateOption == 2 ||
          (emailTemplateOption == 1 &&
            customTemplates.length > 0 &&
            customTemplateId != "")))
    ) {
      return false;
    } else {
      return true;
    }
  };

  const showActivateOrNot = async () => {
    let email_data = {};
    email_data = await getEmail();
    console.log(
      referralInitData?.referral?.referralTitle,
      referralResumeData?.resumes
    );
    if (
      referralInitData?.referral?.emailTemplateOption &&
      referralInitData.referral.emailTemplateOption != "" &&
      referralInitData?.referral?.referralTitle &&
      referralInitData.referral.referralTitle != "" &&
      referralResumeData?.resumes &&
      referralResumeData.resumes.length > 0 &&
      referralResumeData.resumes.some((resume) => resume.validation === 1) &&
      referralResumeData.resumes.some(
        (resumeObj) => resumeObj.resumeId === referralInitData.referral.resumeId
      )
    ) {
      setEmailTemplateOption(referralInitData.referral.emailTemplateOption);
      setCustomTemplateId(referralInitData.referral?.customTemplateId || "");
      setReferralTitle(referralInitData.referral.referralTitle);
      setSelectedResumeId(referralInitData.referral.resumeId);
      setShowIncompleteDataError(false);
      console.log("showActivateOrNot", email_data);

      if (!useStateStore.getState().editEmailSettings) {
        writeEmail(
          referralInitData.referral.referralTitle,
          referralInitData.referral.resumeId,
          email_data
        );
      }
    } else {
      setShowIncompleteDataError(true);
    }
  };

  const showActivateOrNot1 = () => {
    console.log(
      "showActivateOrNot, referralInitData",
      referralInitData,
      editEmailSettings
    );
    console.log(
      referralInitData?.referral?.referralTitle,
      referralResumeData?.resumes
    );
    if (
      referralInitData?.referral?.emailTemplateOption &&
      referralInitData.referral.emailTemplateOption != "" &&
      referralInitData?.referral?.referralTitle &&
      referralInitData.referral.referralTitle != "" &&
      referralResumeData?.resumes &&
      referralResumeData.resumes.length > 0 &&
      referralResumeData.resumes.some((resume) => resume.validation === 1) &&
      referralResumeData.resumes.some(
        (resumeObj) => resumeObj.resumeId === referralInitData.referral.resumeId
      )
    ) {
      setEmailTemplateOption(referralInitData.referral.emailTemplateOption);
      setCustomTemplateId(referralInitData.referral?.customTemplateId || "");
      setReferralTitle(referralInitData.referral.referralTitle);
      setSelectedResumeId(referralInitData.referral.resumeId);
      setShowIncompleteDataError(false);
    } else {
      setShowIncompleteDataError(true);
    }
  };

  const [customTemplateId, setCustomTemplateId] = useState("");
  const customTemplates = useStateStore((state) => state.customTemplates);
  const [emailTemplateOption, setEmailTemplateOption] = useState(-1);

  useEffect(() => {
    setSaveButtonClickAndSuccess(false);
    setNowShowMainSendEmailButton(false);
  }, []);

  useEffect(() => {
    mixpanel.identify(userData.email);
    mixpanel.people.increment("secret_network_popup step_2 dashboard");
    mixpanel.track("secret_network_popup step_2 dashboard", {
      email: userData?.email,
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });

    console.log("referral_main", referralInitData, referralResumeData?.resumes);
    console.log("this use effect", emailAutomationReferral, editEmailSettings);
    setStepNoReferralFeedback(2);
    showActivateOrNot1();

    let timeout;
    const interval = setInterval(() => {
      if (
        !useStateStore.getState().emailAutomationReferral ||
        (useStateStore.getState().emailAutomationReferral &&
          useStateStore.getState().saveButtonClickAndSuccess)
      ) {
        clearTimeout(timeout);
        clearInterval(interval);
        showActivateOrNot();
      }
    }, 100);

    timeout = setTimeout(() => {
      clearInterval(interval);
      showActivateOrNot();
    }, 5000);

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, [referralResumeData, referralInitData, editEmailSettings]);

  // useEffect(() => {
  //   if (initialString) {
  //     const words = initialString.split(" ");
  //     let wordIndex = 0;
  //     const intervalId = setInterval(() => {
  //       if (wordIndex < words.length) {
  //         setText((prevText) => {
  //           const newText =
  //             prevText + (wordIndex > 0 ? " " : "") + (words[wordIndex] || "");
  //           if (textareaRef.current) {
  //             textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
  //           }
  //           return newText;
  //         });
  //         wordIndex++;
  //       } else {
  //         setNowShowMainSendEmailButton(true);
  //         clearInterval(intervalId);
  //       }
  //     }, 100);
  //     return () => clearInterval(intervalId);
  //   }
  // }, [initialString]);

  return (
    <div style={{ marginTop: "50px" }}>
      <button
        id="email-block-go-back"
        className="btn main-referral-goback"
        onClick={() => {
          mixpanel.identify(userData.email);
          mixpanel.people.increment(
            "go_back_button secret_network_modal_step_2 dashboard"
          );
          mixpanel.track(
            "go_back_button secret_network_modal_step_2 dashboard",
            {
              email: userData?.email,
              plan_details: userData?.planDetails || {},
              v2Id: userData?.planDetails?.v2Id || "",
            }
          );

          setSendEmail(false);
        }}
      >
        <i className="fas fa-arrow-left mr-2"></i> Go Back
      </button>

      <div className="main-referral-email-title">
        {(showImcompleteDataError || showEmailSettings) && (
          <>
            {" "}
            <div className="main-referral-custom-template">
              <Form.Label> Choose a option to generate AI Emails</Form.Label>
              <Form.Control
                as="select"
                id="emailtemplateoption"
                value={emailTemplateOption}
                onChange={(e) => {
                  setEmailTemplateOption(e.target.value);
                }}
              >
                <option value={-1}>Choose a option</option>
                <option value={1}>Custom Template For Email Generation</option>
                <option value={2}>Our AI For Email Generation</option>
              </Form.Control>
              {emailTemplateOption == 1 ? (
                <>
                  {customTemplates.length > 0 ? (
                    <div className="main-referral-select-custom-template">
                      <p>Select a custom email template created by you</p>
                      <Form.Control
                        as="select"
                        id="emailtemplateoption"
                        value={customTemplateId}
                        onChange={(e) => {
                          setCustomTemplateId(e.target.value);
                        }}
                      >
                        <option value="">Choose a option</option>
                        {customTemplates.map((obj) => {
                          return (
                            <option value={obj.custom_email_uuid}>
                              {obj.template_name}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </div>
                  ) : (
                    <div className="main-referral-no-templates">
                      No custom templates available,{" "}
                      <span
                        className="main-referral-email-error-createprofile"
                        onClick={() =>
                          history.push("/dashboard/customtemplate")
                        }
                      >
                        Create one now
                      </span>
                      <i
                        style={{ color: "white", fontSize: "16px" }}
                        className="fas fa-external-link-alt ml-2"
                      ></i>{" "}
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}

              <>
                {!emailAutomationReferral &&
                  !(
                    referralInitData?.referralTitle &&
                    referralInitData.referralTitle != ""
                  ) && (
                    <>
                      {" "}
                      <Form.Label>Enter job title for Referral</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        value={referralTitle}
                        id="referrerfaltitle"
                        placeholder="Referral Title - marketing manager, analyst etc"
                        onChange={(e) => setReferralTitle(e.target.value)}
                      />
                    </>
                  )}
                {referralResumeData?.resumes &&
                  referralResumeData.resumes.length > 0 &&
                  referralResumeData.resumes.some(
                    (resume) => resume.validation === 1
                  ) && (
                    <>
                      <Form.Label>Choose your Resume / Profile -</Form.Label>
                      <Form.Control
                        as="select"
                        id="resumeId"
                        value={selectedResumeId}
                        onChange={(e) => {
                          referralResumeData.resumes.map((item) => {
                            if (item.resumeId === e.target.value) {
                              resumeData.current = {
                                ...item,
                              };
                            }
                          });
                          setSelectedResumeId(e.target.value);
                        }}
                      >
                        <option value="">Choose your profile</option>
                        {referralResumeData.resumes.map((item) => {
                          if (item.validation !== 1) return null;
                          return (
                            <option value={item.resumeId}>
                              {item.resumename}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </>
                  )}
                {!(
                  referralResumeData?.resumes &&
                  referralResumeData.resumes.length > 0 &&
                  referralResumeData.resumes.some(
                    (resume) => resume.validation === 1
                  )
                ) && (
                  <div className="main-referral-emamil-error-createprofile-block">
                    {referralResumeData.resumes.length > 0
                      ? "Your Lazyapply Profile is not complete."
                      : "You have not created your Lazyapply Profile."}

                    <span
                      className="main-referral-email-error-createprofile"
                      onClick={() => {
                        mixpanel.identify(userData.email);
                        mixpanel.people.increment(
                          "create_now_button secret_network_popup step_2 dashboard"
                        );
                        mixpanel.track(
                          "create_now_button secret_network_popup step_2 dashboard",
                          {
                            email: userData?.email,
                            plan_details: userData?.planDetails || {},
                            v2Id: userData?.planDetails?.v2Id || "",
                          }
                        );
                        window.open(
                          "https://app.lazyapply.com/dashboard/editresumesv2",
                          "_blank"
                        );
                      }}
                    >
                      {referralResumeData.resumes.length > 0
                        ? "Complete Your Profile Now"
                        : "Create Your Profile Now"}
                    </span>
                    <p
                      onClick={() => {
                        fetchUserResumeData();
                        mixpanel.identify(userData.email);
                        mixpanel.people.increment(
                          "already_create_click_now_button secret_network_popup step_2 dashboard"
                        );
                        mixpanel.track(
                          "already_create_click_now_button secret_network_popup step_2 dashboard",
                          {
                            email: userData?.email,
                            plan_details: userData?.planDetails || {},
                            v2Id: userData?.planDetails?.v2Id || "",
                          }
                        );
                      }}
                    >
                      If Already created, click here{" "}
                      {resumefetchingStatus && (
                        <Spinner
                          animation="border"
                          size="sm"
                          style={{ marginLeft: "10px" }}
                        />
                      )}
                    </p>
                  </div>
                )}
              </>

              <div>
                <button
                  disabled={checkDisabled()}
                  onClick={() => {
                    mixpanel.identify(userData.email);
                    mixpanel.people.increment(
                      "activate_ai_emails_button secret_network_popup step_2 dashboard"
                    );
                    mixpanel.track(
                      "activate_ai_emails_button secret_network_popup step_2 dashboard",
                      {
                        email: userData?.email,
                        plan_details: userData?.planDetails || {},
                        v2Id: userData?.planDetails?.v2Id || "",
                      }
                    );
                    activeAiEmails();
                  }}
                  className="main-referral-custom-template-save-button"
                >
                  Save Your Choice{" "}
                  {activeEmailLoading && (
                    <Spinner
                      animation="border"
                      size="sm"
                      style={{ marginLeft: "10px" }}
                    />
                  )}
                </button>
              </div>
            </div>
          </>
        )}
        <PeopleBlock
          employee={employeeSelected}
          showSendEmailButton={false}
          emailData={emailData}
          EmailDataComponent={
            <>
              {Object.keys(emailData).length > 0 ? (
                <div className="main-referral-company-domain">
                  {emailData?.email && emailData.email != "" ? (
                    emailData.email
                  ) : (
                    <span style={{ color: "red" }}>Email Not Found!</span>
                  )}
                </div>
              ) : (
                <>
                  <div className="main-referral-modal-loading-email">
                    Getting Email...{" "}
                    <Spinner
                      animation="border"
                      size="sm"
                      style={{ marginLeft: "10px" }}
                    />
                  </div>
                </>
              )}
            </>
          }
        />
        <div className="main-referral-edit-email-settings-div">
          {!emailAutomationReferral && (
            <button
              onClick={() => {
                setSaveButtonClickAndSuccess(false);
                setShowEmailSettings(!showEmailSettings);
              }}
              id="main-referral-edit-email-settings"
              className="main-referral-edit-email-settings"
            >
              Edit Your Email Settings
            </button>
          )}
        </div>

        {emailNotFound && (
          <p
            style={{
              margin: "20px 0px",
              color: "#007bff",
              fontWeight: "500",
            }}
          >
            Email not found, try again with another employee!
          </p>
        )}
        {showLoading && (
          <div className="main-referral-modal-loading-email-body">
            Writing Email Using AI...{" "}
            <Spinner
              animation="border"
              size="sm"
              style={{ marginLeft: "10px" }}
            />
          </div>
        )}
        <Form.Control
          style={{ marginTop: "20px" }}
          required
          disabled={emailNotFound || showImcompleteDataError}
          type="text"
          value={emailTitle}
          id="emailsubject"
          placeholder="Email Subject Here..."
          onChange={(e) => setEmailTitle(e.target.value)}
        />
      </div>
      <div className="main-referral-email-body">
        <EmailComposer
          setNowShowMainSendEmailButton={setNowShowMainSendEmailButton}
          initialString={initialString}
          emailNotFound={emailNotFound}
          showImcompleteDataError={showImcompleteDataError}
        ></EmailComposer>
        {/* <ReactQuill
          theme="snow"
          value={text}
          onChange={setText}
          placeholder="Email Body Here..."
          readOnly={emailNotFound || showImcompleteDataError}
          modules={{
            toolbar: showImcompleteDataError ? false : true,
          }}
          style={{ height: showImcompleteDataError ? 100 : 300 }}
          formats={[
            "header",
            "font",
            "bold",
            "italic",
            "underline",
            "strike",
            "list",
            "bullet",
            "link",
          ]}
        />

        <Form.Control
          as="textarea"
          rows={showImcompleteDataError ? 4 : 12}
          disabled={emailNotFound || showImcompleteDataError}
          id="emailbody"
          placeholder="Email Body Here..."
          value={text}
          ref={textareaRef}
          onChange={(e) => setText(e.target.value)}
        /> */}
      </div>
      <div className="main-referral-emailsend-button">
        <button
          style={{
            background: "cadetblue",
          }}
          disabled={showLoading || regenerateLoading}
          onClick={async () => {
            mixpanel.identify(userData.email);
            mixpanel.people.increment(
              "regenerate_email_button secret_network_popup step_2 dashboard"
            );
            mixpanel.track(
              "regenerate_email_button secret_network_popup step_2 dashboard",
              {
                email: userData?.email,
                plan_details: userData?.planDetails || {},
                v2Id: userData?.planDetails?.v2Id || "",
              }
            );

            setInitialString("");
            setText("");
            setRegenerateLoading(true);
            await writeEmail("", "", emailData);
            setRegenerateLoading(false);
          }}
        >
          Regenerate Email
          {regenerateLoading && (
            <Spinner
              animation="border"
              size="sm"
              style={{ marginLeft: "10px" }}
            />
          )}
        </button>
        <button
          disabled={sendEmailButtonLoadingStatus || !nowShowMainSendEmailButton}
          id="referral-main-send-email"
          style={
            emailAutomationReferral
              ? {
                  opacity: 0,
                  position: "absolute",
                  pointerEvents: "auto",
                  width: "0",
                  height: "0",
                  overflow: "hidden",
                }
              : {}
          }
          onClick={() => {
            mixpanel.identify(userData.email);
            mixpanel.people.increment(
              "send_email_button secret_network_popup step_2 dashboard"
            );
            mixpanel.track(
              "send_email_button secret_network_popup step_2 dashboard",
              {
                email: userData?.email,
                plan_details: userData?.planDetails || {},
                v2Id: userData?.planDetails?.v2Id || "",
              }
            );
            sendEmail();
          }}
        >
          Send Email
          {sendEmailButtonLoadingStatus && (
            <Spinner
              animation="border"
              size="sm"
              style={{ marginLeft: "10px" }}
            />
          )}
        </button>
      </div>
    </div>
  );
}

export default EmailBlock;
