import React, { useEffect, useState, useRef } from "react";
import Sidebar from "./sidebar";
import "./dashboard.css";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import Homedash from "./homedash";
import MainDashboard from "../maindashboard/index";
import EditresumedashAdditionalInfo from "./editresumedashadditional";
import Applieddash from "./applieddash";
import Editresumedash from "./editresumedash";
import EditResumes from "./editresumes";
import EmailAutomation from "../maindashboard/emailautomation";
import EmailAutomationPermission from "../maindashboard/emailautomationpermission";
import EmailAutomationJobs from "../maindashboard/emailautomationjobs";
import CustomTemplate from "../referral/CustomTemplate"
// import RightEnd from "./rightend";
import { useStateStore } from "../../store/index";
import LazyapplyXAnalytics from "./LazyapplyXAnalytics";
import CustomAlert from "../modal";
import Privacy from "./Privacy";
import DataManagement from "./DataManagement";
// import GetEmails from "./getemails.js";
import UserInvites from "./UserInvites";
import TargetedSearch from "./targetedSearch";
import CoverLetter from "./CoverLetter";
import LinkedinSendEmail from "./LinkedinSendEmail";
import LazyapplyX from "./LazyapplyX";
import LazyapplyXSessions from "./LazyapplyXSessions";
import LazyapplyXIndividualSession from "./LazyapplyXIndividualSession";
import LazyapplyXFourth from "./LazyapplyXFourth";
import Indeed from "./indeed";
import Linkedin from "./linkedin";
import Ziprecruiter from "./ziprecruiter";
import Dice from "./dice";
// import Testing from "./testing";
import EditResumeV2 from "./editresumesv2";
import MainResume from "./mainresume";
import GlassDoor from "./glassdoor";
import CareerBuilder from "./careerBuilder";
import Monster from "./monster";
import Foundit from "./foundit";
import Seek from "./seek";
import SimplyHired from "./simplyHired";
import PaymentSuccess from "./payment-success";

import Queries from "./Queries/Queries";
import ReferralMain from "../referral/ReferralMain";

function Dashboard() {
  const [message, setmessage] = useState("");
  const [title, settitle] = useState("");
  const [modalshow, setmodalshow] = useState(false);
  const dashRight = useRef(null);
  const dashLeft = useRef(null);
  const [heightOfMiddle, setHeightOfMiddle] = useState(0);
  const userData = useStateStore((state) => state.userData);
  const location = useLocation();
  const heightChange = useStateStore((state) => state.userData);
  const token = useStateStore((state) => state.token);
  const setheightMain = useStateStore((state) => state.setheightMain);
  useEffect(() => {
    console.log("dash style");
    let height = 0;

    if (dashRight) height = dashRight.current.scrollHeight;

    // if (document.querySelector(".dashboardRightEnd"))
    //   document.querySelector(".dashboardRightEnd").style.height = height + "px";
    if (document.querySelector(".dashboardLeft"))
      document.querySelector(".dashboardLeft").style.height = height + "px";
    //console.log(height+ 'height')
    setHeightOfMiddle(height);
    setheightMain(height);
    //console.log(window.innerWidth);
    // if (window.innerWidth <= 1150) {
    //   if (document.querySelectorAll(".dashboardRightEnd")[0])
    //     document.querySelectorAll(".dashboardRightEnd")[0].style.display =
    //       "none";
    // }
    console.log("dash style");
  }, [location, heightChange]);

  return (
    <div className="dashboard">
      <CustomAlert message={message} s={modalshow} title={title}></CustomAlert>
      {/* <div className="dashboardLeft" ref={dashLeft}> */}
        <Sidebar height={heightOfMiddle} userData={userData}></Sidebar>
      {/* </div> */}
      <div className="dashboardRight" ref={dashRight}>
        <Switch>
          <Route exact path="/dashboard/main">
            <MainDashboard></MainDashboard>
          </Route>
          {/* <Route exact path="/dashboard/emailAutomation">
            <EmailAutomation></EmailAutomation>
          </Route> */}
          {/* <Route exact path="/dashboard/emailAutomationPermission">
            <EmailAutomationPermission></EmailAutomationPermission>
          </Route> */}
          {/* <Route exact path="/dashboard/emailAutomation/:id">
            <EmailAutomationJobs></EmailAutomationJobs>
          </Route> */}
          <Route exact path={`/dashboard`}>
            <Homedash></Homedash>
          </Route>
          <Route exact path={`/dashboard/linkedin`}>
            <Linkedin></Linkedin>
          </Route>
          <Route exact path={`/dashboard/indeed`}>
            <Indeed></Indeed>
          </Route>
          <Route exact path={`/dashboard/dice`}>
            <Dice></Dice>
          </Route>
          <Route exact path={`/dashboard/ziprecruiter`}>
            <Ziprecruiter></Ziprecruiter>
          </Route>
          <Route exact path={`/dashboard/glassdoor`}>
            <GlassDoor></GlassDoor>
          </Route>
          <Route exact path={`/dashboard/careerBuilder`}>
            <CareerBuilder></CareerBuilder>
          </Route>
          <Route exact path={`/dashboard/monster`}>
            <Monster></Monster>
          </Route>
          <Route exact path={`/dashboard/foundit`}>
            <Foundit></Foundit>
          </Route>
          <Route exact path={`/dashboard/simplyHired`}>
            <SimplyHired></SimplyHired>
          </Route>
          <Route exact path={`/dashboard/payment-success`}>
            <PaymentSuccess></PaymentSuccess>
          </Route>
          <Route exact path={`/dashboard/seek`}>
            <Seek></Seek>
          </Route>
          {/* <Route exact path={`/dashboard/editresumes`}>
            <EditResumes></EditResumes>
          </Route>
          <Route exact path={`/dashboard/editresumes/:id`}>
            <Editresumedash></Editresumedash>
          </Route> */}
          <Route exact path={`/dashboard/editresumesv2`}>
            <MainResume></MainResume>
          </Route>
          <Route exact path={`/dashboard/editresumesv2/:id`}>
            <EditResumeV2></EditResumeV2>
          </Route>
          <Route exact path={`/dashboard/editresume/additionalInfo/:id`}>
            <EditresumedashAdditionalInfo></EditresumedashAdditionalInfo>
          </Route>
          <Route exact path={`/dashboard/userinvites`}>
            <UserInvites></UserInvites>
          </Route>
          <Route exact path={`/dashboard/editresume`}>
            <Editresumedash></Editresumedash>
          </Route>
          <Route exact path={`/dashboard/editresume/additionalInfo`}>
            <EditresumedashAdditionalInfo></EditresumedashAdditionalInfo>
          </Route>
          <Route exact path={`/dashboard/appliedjobs`}>
            <Applieddash></Applieddash>
          </Route>
          {/* <Route exact path={`/dashboard/getemails`}>
            <GetEmails></GetEmails>
          </Route> */}
          <Route exact path={`/dashboard/hremails`}>
            <TargetedSearch></TargetedSearch>
          </Route>
          <Route exact path={`/dashboard/lazyapply-x`}>
            <LazyapplyX></LazyapplyX>
          </Route>
          <Route exact path={`/dashboard/linkedin-send-email`}>
            <LinkedinSendEmail></LinkedinSendEmail>
          </Route>
          <Route exact path={`/dashboard/lazyapplyx-privacy`}>
            <Privacy></Privacy>
          </Route>
          <Route exact path={`/dashboard/referral`}>
            <ReferralMain/>
          </Route>
          <Route exact path={`/dashboard/lazyapplyx-data-management`}>
            <DataManagement></DataManagement>
          </Route>
          <Route exact path={`/dashboard/lazyapply-x/sessions`}>
            <LazyapplyXSessions></LazyapplyXSessions>
          </Route>
          <Route exact path={`/dashboard/lazyapply-x/analytics/:id`}>
            <LazyapplyXAnalytics></LazyapplyXAnalytics>
          </Route>
          <Route exact path={`/dashboard/lazyapply-x/sessions/:id`}>
            <LazyapplyXIndividualSession></LazyapplyXIndividualSession>
          </Route>
          <Route exact path={`/dashboard/lazyapply-x/sessions/:id/sendEmails`}>
            <LazyapplyXFourth></LazyapplyXFourth>
          </Route>
          <Route exact path={`/dashboard/coverletter`}>
            <CoverLetter></CoverLetter>
          </Route>
          <Route exact path={`/dashboard/customtemplate`}>
            <CustomTemplate></CustomTemplate>
          </Route>
          <Route exact path={`/dashboard/queries`}>
            <Queries />
          </Route>
          <Route>
            <Redirect to="/dashboard" />
          </Route>
        </Switch>
      </div>
      {/* <div className="dashboardRightEnd">
        <RightEnd></RightEnd>
      </div> */}
    </div>
  );
}

export default Dashboard;
